import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Modal from "../../../components/layout/modal/modal.component"
import LoginForm from "../../../components/loginForm/loginForm.component";
import "./member.attend.component.scss";

const MemberAttend = () => {
    return (
        <>
            <div className={"member-attend-container"}>
                <div className="header">
                    <div className="header-text">Member Attend</div>
                    <div className="barcode-container">
                        <div className="bi bi-upc-scan"></div>
                        <div className="barcode-text">Scan to Attend</div>
                    </div>
                    <div className="header-footer">Or</div>
                </div>
                <div className="login-section">
                    <LoginForm />
                </div>
            </div>
        </>
    )
}

export default MemberAttend