import { createAction } from "@reduxjs/toolkit";

export const selectKioskSettings = (state: any) => state.kioskSettings;

export const selectMainTimerTimeout = (state: any) => !!Object.keys(state.kioskSettings.settings).length
    && state.kioskSettings.settings.timeoutOnKioskEnabled
    ? ((state.kioskSettings.settings.timeoutOnKioskMinutes * 60) + state.kioskSettings.settings.timeoutOnKioskSeconds)
    : 0;

export const selectSuccessScreenTimerTimeout = (state: any) => !!Object.keys(state.kioskSettings.settings).length
    && state.kioskSettings.settings.timeoutOnSuccessScreenEnabled
    ? ((state.kioskSettings.settings.timeoutOnSuccessScreenMinutes * 60) + state.kioskSettings.settings.timeoutOnSuccessScreenSeconds)
    : 0;