import { createSlice } from "@reduxjs/toolkit";
import { IDLE_TIMER_TYPE } from "./idleTimer.types";

const disableTimers = (timers: any) => {
    for (var key in timers) {
        var timer = timers[key];
        timer.active = false;
        timer.timeout = 0;
    }
}

const startTimer = (timerType: string, timers: any, timeout: number) => {
    disableTimers(timers);

    var timer = timers[timerType];
    timer.active = true;
    timer.timeout = timeout;
}

const idleTimerSlice = createSlice({
    name: "idleTimer",
    initialState: {
        timers: {
            MAIN: {
                active: false,
                timeout: 0
            },
            SUCCESS_SCREEN: {
                active: false,
                timeout: 0
            }
        }
    },
    reducers: {
        startMainTimer: (state, action) => {
            startTimer(IDLE_TIMER_TYPE.MAIN, state.timers, action.payload);
        },
        startSuccessScreenTimer: (state, action) => {
            startTimer(IDLE_TIMER_TYPE.SUCCESS_SCREEN, state.timers, action.payload);
        }
    }
});

export const { startMainTimer, startSuccessScreenTimer } = idleTimerSlice.actions;
export default idleTimerSlice.reducer;
