import * as React from "react";
import { useState, useRef, useCallback, useEffect } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import SignOutButton from "../signOutButton/signOutButton.component";
import "./attendConfirmation.component.scss"
import { ClassItemType } from "../../types/class/classItem.type";
import IdleTimer from "../idleTimer/idleTimer.component";
import ClassDetail from "../classItem/classDetail/classDetail.component";
import { Button } from "@progress/kendo-react-buttons";
import AttendanceReceipt2 from "../attendanceReceipt2/attendanceReceipt2.component";
import XplLoader from "../../components/layout/xplLoader/xplLoader.component";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { sendEmail } from "../../services/api/attendUserApi";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { IDLE_TIMER_TYPE } from "../../store/idleTimer/idleTimer.types";
import { selectSuccessScreenTimerTimeout } from "../../store/kioskSettings/kioskSettings.selector";
import { prepareReceipt, printReceipt } from "../../services/api/printerApi.service";
import store from "../../store/store";
import { startSuccessScreenTimer } from "../../store/idleTimer/idleTimer.reducer";

type AttendConfirmationProps = {
    classItem: ClassItemType,
    onClose?: () => void
}

const AttendTitle = () => {
    return (
        <div className={"attend-title"}>
            <span className={"k-icon"} />
            Attended Successfully
        </div>
    );
}

const AttendConfirmation = (props: AttendConfirmationProps) => {
    const componentRef = useRef(null);
    const kioskSettings = useSelector((state: any) => state.kioskSettings);
    const [loading, setLoading] = useState(false);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [printing, setPrinting] = useState(false);
    const user = useSelector((state: any) => state.user);
    const timerTimeout = useSelector(selectSuccessScreenTimerTimeout);

    const reactToPrintContent = useCallback(() => {
        return componentRef.current;
    }, [componentRef.current]);

    const onPrint = () => {
        setPrinting(true);
    }

    const onEmail = () => {
        setSendingEmail(true);
    }

    useEffect(() => {
        if (!kioskSettings.settings.printReceiptEnabled) {
            return;
        }

        setPrinting(true);
    }, []);

    useEffect(() => {
        store.dispatch(startSuccessScreenTimer(timerTimeout));
    }, [timerTimeout]);

    useEffect(() => {
        if (sendingEmail) {
            processSendEmail()
        } else if (printing) {
            setLoading(true);
            processPrintReceipt()
        }
    }, [sendingEmail, printing]);

    const processSendEmail = () => {
        setLoading(true);

        const receiptContent = reactToPrintContent();
        drawDOM(receiptContent, { paperSize: "A4" })
            .then((group) => exportPDF(group))
            .then((dataUri) => {
                const classTime = format(new Date(props.classItem.startTime), "HH:mm a");
                sendEmail({
                    className: props.classItem.title,
                    classTime: classTime,
                    attendeeName: user.name,
                    receiptBase64: dataUri.split(";base64,")[1]
                }).then(() => {
                    setSendingEmail(false);
                    setLoading(false);
                });
            });
    }

    const processPrintReceipt = () => {
        if (!kioskSettings.settings.printer) {
            return;
        }

        const receiptContent = reactToPrintContent();
        return drawDOM(receiptContent)
            .then((group) =>
                exportPDF(group, {
                    margin: {
                        left: "4mm",
                        right: "0mm"
                    }
                }))
            .then((dataUri) => {
                prepareReceipt(dataUri.split(";base64,")[1]).then((receiptData: any) => {
                    receiptData.printSettings.printer = kioskSettings.settings.printer.name;

                    printReceipt(receiptData);

                    setPrinting(false);
                    setLoading(false);
                });
            });
    }

    return (
        <Window title={<AttendTitle />} className={"attend-confirmation-window"} onClose={props.onClose}
            maximizeButton={() => null}
            minimizeButton={() => null}
            initialWidth={600}
            resizable={false}
            modal={true}>
            <div className={"container content"}>
                <ClassDetail classItem={props.classItem} />
            </div>
            <div className={"footer"}>
                <SignOutButton />
                <IdleTimer timerType={IDLE_TIMER_TYPE.SUCCESS_SCREEN} />
                {kioskSettings.settings.printer && <Button className="btn-print" iconClass="btn-print-icon" onClick={onPrint}>Print</Button>}
                {kioskSettings.settings.emailReceiptEnabled && <Button className="btn-email" iconClass="btn-email-icon" onClick={onEmail} themeColor={"primary"}>Email</Button>}
            </div>
            {(printing || sendingEmail) &&
                <Window className={"attend-receipt-window"} onClose={props.onClose}
                    title={false}
                    maximizeButton={() => null}
                    minimizeButton={() => null}
                    initialWidth={600}
                    resizable={false}>
                    <div className="receipt-window-title">Printing Receipt</div>
                    <AttendanceReceipt2 classItem={props.classItem} ref={componentRef} />
                </Window >
            }
            {loading && <XplLoader />}
        </Window>
    );
}

export default AttendConfirmation