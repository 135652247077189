import * as React from "react";
import { useState, useRef } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import "./kioskPin.component.scss"
import PinInput from "react-pin-input";
import { useNavigate } from "react-router-dom";
import { activateKioskByPinCode } from "../../../services/auth.service";
import XplLoader from "../../layout/xplLoader/xplLoader.component";
import { setKioskId } from "../../../store/kioskSettings/kioskSettings.reducer";
import store from "../../../store/store";

const KioskPinTitle = () => {
    return (
        <div className={"kiosk-pin-title container"}>
            <div className="row justify-content-center">
                <div className="col-auto">Kiosk Pin Code</div>
            </div>
        </div>
    );
}

const KioskPin = () => {
    const pinInputRef = useRef<PinInput>(null);
    const [loading, setLoading] = useState(false);
    const [kioskPinVisible, setKioskPinVisible] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);

    const onKioskPinComplete = (pinCode: string) => {
        setLoading(true);
        activateKioskByPinCode(pinCode).then((response: any) => {
            store.dispatch(setKioskId(response.kioskId));
            setKioskPinVisible(false);
        }).catch((error) => {
            setErrorMessage(error.message);
            pinInputRef.current.clear();
        }).finally(() => {
            setLoading(false);
        });
    }

    return (
        <>
            {kioskPinVisible && <Window title={<KioskPinTitle />} className={"kiosk-pin-window"}
                maximizeButton={() => null}
                minimizeButton={() => null}
                initialWidth={300}
                resizable={false}
                closeButton={() => null}
                draggable={false}
                modal>
                {loading && <XplLoader />}
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <PinInput
                                length={4}
                                focus
                                secret
                                ref={pinInputRef}
                                type="numeric"
                                onComplete={onKioskPinComplete} />
                        </div>
                    </div>
                    {errorMessage &&
                        <div className="row justify-content-center error-container">
                            <div className="col-1 error-icon"></div>
                            <div className="col-auto error-text">
                                {errorMessage}
                            </div>
                        </div>}
                </div>
            </Window>
            }
        </>
    );
}

export default KioskPin