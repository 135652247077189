import * as React from "react";
import { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import "./classItem.component.scss";
import { ClassItemType } from "../../types/class/classItem.type";

type ClassItemProps = {
    classItem: ClassItemType,
    onAttend: (e: ClassItemType) => void
}

const ClassItem = (props: ClassItemProps) => {
    const [attendBtnDisabled, setAttendBtnDisabled] = useState(false);

    const onAttendClick = () => {
        setAttendBtnDisabled(true);
        props.onAttend(props.classItem);
    }

    return (
        <div className={"list-item"} key={props.classItem.id}>
            <div>
                <div className={"row"}>
                    <div className={"col start-time"}>
                        {props.classItem.startTimeFormat}
                    </div>
                    {/* <div className={"col spots-left"}>
                        {props.classItem.spotsLeft}
                    </div> */}
                </div>
                <div className={"row"}>
                    <div className={"col title"}>
                        {props.classItem.title}
                    </div>
                    <div className={"col col-btn-attend"}>
                        <Button className="btn-attend" iconClass="btn-sign-out-icon" onClick={onAttendClick}
                            disabled={attendBtnDisabled}>
                            Attend
                        </Button>
                    </div>
                </div>
                <div className={"row class-info-row"}>
                    <div className={"col"}>
                        {props.classItem.instructor && <span className={"class-info-item instructor"}>{props.classItem.instructor}</span>}
                        {props.classItem.rank && <span className={"class-info-item rank"}>{props.classItem.rank}</span>}
                        {props.classItem.facilityName && <span className={"class-info-item facility"}>{props.classItem.facilityName}</span>}
                        {props.classItem.locationName && <span className={"class-info-item location"}>{props.classItem.locationName}</span>}
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ClassItem