import * as React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import defaultOrganizationLogo from "../../../assets/defaultOrganizationLogo.png";
import { selectAuthInfo } from "../../../store/auth/auth.selector";
import KioskPin from "../../shared/kioskPin/kioskPin.component";
import { selectKioskSettings } from "../../../store/kioskSettings/kioskSettings.selector";
import { selectAppInfo } from "../../../store/appInfo/appInfo.selector";
import KioskSetup from "../../shared/kioskSetup/kioskSetup.component";

const AdminHomePage = () => {
    const appInfo = useSelector(selectAppInfo);
    const kioskSettings = useSelector(selectKioskSettings);

    return (
        <div className="kiosk-app-content">
            <div className="header">
                <img className="company-logo" src={defaultOrganizationLogo} />
                <div className="company-header-text">Welcome to Xplor Kiosk</div>
            </div>
            {!kioskSettings.kioskId && <KioskPin />}
            {kioskSettings.kioskId && !appInfo.initialized && <KioskSetup />}
        </div>
    );
}

export default AdminHomePage;