import * as React from "react";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectKioskSettings } from "../../../store/kioskSettings/kioskSettings.selector";
import Home from "../../../components/homePage/home.component";
import defaultBackgroundImage from "../../../assets/defaultBackgroundImage.png";

const HomeRoute = () => {
    const kioskSettings = useSelector(selectKioskSettings);
    const backgroundImage = kioskSettings.settings.backgroundImage && kioskSettings.settings.backgroundImage.content 
        ? kioskSettings.settings.backgroundImage.content : defaultBackgroundImage;

    return (
        <div className="kiosk-app-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <Home />
            <Outlet />
        </div>
    );
}

export default HomeRoute;