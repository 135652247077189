import * as React from "react";
import { useState } from "react";
import "./memberClassList.component.scss";
import { ClassItemType } from "../../types/class/classItem.type";
import ClassItem from "../classItem/classItem.component";
import { attendUser } from "../../services/api/attendUserApi";
import XplLoader from "../layout/xplLoader/xplLoader.component";
import AttendConfirmation from "../attendConfirmation/attendConfirmation.component";
import { getSessions } from "../../services/api/attendUserApi";
import { useDispatch, useSelector } from "react-redux";
import { setMemberClasses } from "../../store/memberClasses/memberClasses.action";
import { selectMainTimerTimeout } from "../../store/kioskSettings/kioskSettings.selector";
import store from "../../store/store";
import { startMainTimer } from "../../store/idleTimer/idleTimer.reducer";

type MemberClassListProps = {
    yourClasses: Array<ClassItemType>,
    // availableClasses: Array<ClassItemType>
}

const MemberClassList = (props: MemberClassListProps) => {
    const [loading, setLoading] = useState(false);
    const [showAttendConfirmation, setShowAttendConfirmation] = useState(false);
    const [selectedClassItem, setSelectedClassItem] = useState(null);
    const dispatch = useDispatch();
    const mainTimerTimeout = useSelector(selectMainTimerTimeout);
    
    const onAttend = (classItem: ClassItemType) => {
        setLoading(true);

        setSelectedClassItem(classItem);

        attendUser({
            id: classItem.id,
            startTimeUtc: classItem.startTimeUtc
        }).then(() => {
            setShowAttendConfirmation(true);

            return getSessions().then((sessions: ClassItemType) => {
                dispatch(setMemberClasses({
                    yourClasses: sessions
                }));
            });

        }).catch((error) => {
            alert("error");
        }).finally(() => {
            setLoading(false);
        });
    }

    const onAttendConfirmationClose = () => {
        setShowAttendConfirmation(false);

        store.dispatch(startMainTimer(mainTimerTimeout));
    }

    return (
        <>
            {loading && <XplLoader />}
            {showAttendConfirmation && <AttendConfirmation classItem={selectedClassItem} onClose={onAttendConfirmationClose} />}
            <div className={"class-list-container overflow-auto xpl-scrollbar"}>
                <div className={"class-list"}>
                    <div className={"list-header"}>
                        Your Classes
                    </div>
                    <div className={"container list-items"}>
                        {props.yourClasses.map(item => <ClassItem key={item.id} classItem={item} onAttend={onAttend} />)}
                    </div>
                </div>
                {/* <div className={"class-list"}>
                    <div className={"list-header"}>
                        Available Classes
                    </div>
                    <div className={"container list-items"}>
                        {props.availableClasses.map(item => <ClassItem key={item.id} classItem={item} />)}
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default MemberClassList