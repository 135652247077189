import * as React from "react"
import { useState, useRef, useEffect } from "react"
import { Icon } from "@progress/kendo-react-common";
import { Label } from '@progress/kendo-react-labels';
import { InputSuffix, TextBox } from "@progress/kendo-react-inputs";
import { useSelector } from "react-redux";
import { FieldWrapper } from "@progress/kendo-react-form";
import * as KioskBoard from "kioskboard";
import { getKioskBoardConfig } from "./kioskBoardConfig";
import "./formInput.component.scss";

type FormInputProps = {
    id?: string,
    type: string,
    className?: string,
    label?: string,
    value?: string,
    required?: boolean,
    valid?: boolean,
    onChange?: (e: any) => void
}

const FormInput = (props: FormInputProps) => {
    const { id, type, label, value, valid, ...others } = props;
    const [textBoxType, setTextBoxType] = useState(type);
    const kioskSettings = useSelector((state: any) => state.kioskSettings);
    const [keyboardEnabled, setKeyboardEnabled] = useState(false);

    const onClickShowPassword = () => {
        setTextBoxType(props.type == "password" && textBoxType == "password" ? "text" : props.type);
    };

    const textboxRef = useRef(null);

    useEffect(() => {
        if (!Object.keys(kioskSettings.settings).length) {
            return;
        }

        setKeyboardEnabled(kioskSettings.settings.virtualKeyboardEnabled);
    }, [kioskSettings.settings.virtualKeyboardEnabled]);

    useEffect(() => {
        if (!keyboardEnabled || !textboxRef || !textboxRef.current.element) {
            return;
        }

        const kioskBoardConfig = getKioskBoardConfig()
        KioskBoard.run(textboxRef.current.element, kioskBoardConfig);

        // Kioskboard uses native javascript function to trigger an event - "dispatchEvent". It doesn't work here.
        // That's why we need to subscribe on "onChange" event and re-trigger it.
        textboxRef.current.element.onchange = () => {
            props.onChange({ target: textboxRef.current.element })
        }
    }, [textboxRef, keyboardEnabled]);

    return (
        <FieldWrapper>
            <Label>{label}</Label>
            {keyboardEnabled &&
                <TextBox id={id} 
                    valid={valid}
                    type={textBoxType}
                    ref={textboxRef}
                    onChange={props.onChange}
                    data-kioskboard-specialcharacters={true}
                    value={value}
                    {...others}
                    suffix={() => (
                        <InputSuffix onClick={onClickShowPassword}>
                            <Icon />
                        </InputSuffix>
                    )}
                />
            }

            {!keyboardEnabled &&
                <TextBox id={id} {...others}
                    valid={valid}
                    type={textBoxType}
                    // ref={textboxRef}
                    onChange={props.onChange}
                    data-kioskboard-specialcharacters={true}
                    value={value}
                    suffix={() => (
                        <InputSuffix onClick={onClickShowPassword}>
                            <Icon />
                        </InputSuffix>
                    )}
                />
            }

        </FieldWrapper>
    )
}

export default FormInput;