import * as React from "react";
import { Outlet } from "react-router-dom";
import defaultBackgroundImage from "../../assets/defaultBackgroundImage.png";
import AdminHomePage from "../../components/admin/homePage/adminHomePage.component";

const AdminHomeRoute = () => {
    return (
        <div className="kiosk-app-container" style={{ backgroundImage: `url(${defaultBackgroundImage})` }}>
            <AdminHomePage />
            <Outlet />
        </div>
    );
}

export default AdminHomeRoute