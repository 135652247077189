import * as React from "react";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import KioskApp from './KioskApp';
import store from "./store/store";
import "./index.scss";
import './styles/kendo/css/index.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
    // <StrictMode>
        <Provider store={store}>
            <KioskApp />
        </Provider>
    // </StrictMode>
);