import * as React from "react";
import "./classDetail.component.scss";
import { ClassItemType } from "../../../types/class/classItem.type";
import instructorImage from "../../../assets/member-icon-16.png";
import rankImage from "../../../assets/rank-18.png";
import facilityImage from "../../../assets/facility-18.png";
import locationImage from "../../../assets/location-18.png";

type ClassDetailProps = {
    classItem: ClassItemType
}

const ClassDetail = (props: ClassDetailProps) => {
    return (
        <div className={"class-detail container"} key={props.classItem.id}>
            <div className={"row"}>
                <div className="col">
                    <span className={"class-time"}>{props.classItem.startTimeFormat}</span>
                </div>
            </div>
            <div className={"row row-title"}>
                <div className="col">
                    <span className={"class-title"}>{props.classItem.title}</span>
                </div>
            </div>
            {props.classItem.instructor && (
                <div className={"row row-details"}>
                    <div className="col-1 p-0 col-icon"><img src={instructorImage} className={"class-detail-img"} /></div>
                    <div className="col-10 class-instructor">{props.classItem.instructor}</div>
                </div>
            )}
            {props.classItem.rank && (
                <div className={"row row-details"}>
                    <div className="col-1 p-0 col-icon"><img src={rankImage} className={"class-detail-img"} /></div>
                    <div className="col-10 col-text"></div>
                </div>
            )}
            {props.classItem.facilityName && (
                <div className={"row row-details"}>
                    <div className="col-1 p-0 col-icon"><img src={facilityImage} className={"class-detail-img"} /></div>
                    <div className="col-10 p-0 col-text">{props.classItem.facilityName}</div>
                </div>
            )}
            {props.classItem.locationName && (
                <div className={"row row-details"}>
                    <div className="col-1 p-0 col-icon"><img src={locationImage} className={"class-detail-img"} /></div>
                    <div className="col-10 p-0 col-text">{props.classItem.locationName}</div>
                </div>
            )}
        </div >
    );
}

export default ClassDetail