import * as React from "react";
import { Navigate } from 'react-router-dom';
import { getLocalStorageAccessToken } from "../../../services/localStorage.service";

const ProtectedRoute = (props: any) => {
    var accessToken = getLocalStorageAccessToken();
    if (!!accessToken) {
        return props.children;
    }
    return <Navigate to="/" replace />;
}

export default ProtectedRoute;