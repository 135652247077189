import * as React from "react";
import { useEffect, useState } from "react";
import { getImage } from "../../services/api/apiHelper";
import { UserInfo } from "../../types/user/userInfo.type"
import "./memberHeaderInfo.component.scss";
import defaultMemberPhoto from "../../assets/member-icon.svg";
import { Window } from "@progress/kendo-react-dialogs";

type MemberHeaderInfoProps = {
    user: UserInfo
}

const MemberHeaderInfo = (props: MemberHeaderInfoProps) => {
    const [memberPhoto, setMemberPhoto] = useState(null);
    const [widgetVisible, setWidgetVisible] = useState(false);

    useEffect(() => {
        if (!props.user.photoUrl) {
            setMemberPhoto(defaultMemberPhoto);
            return;
        }

        getImage(props.user.photoUrl).then((res) => {
            setMemberPhoto(URL.createObjectURL(res));
        });
    }, [props.user]);

    const showWidget = () => {
        setWidgetVisible(true);
    }

    return (
        <>
            <div className={"member-header-info-container"}>
                {!!props.user &&
                    <>
                        <div className={"member-photo"} onClick={showWidget}>
                            <img src={memberPhoto} />
                        </div>
                        <div className={"member-info"}>
                            <div className={"name"}>{props.user.name}</div>
                            <div className={"description"}>Choose a class to attend to...</div>
                        </div>
                    </>
                }
            </div>
            {/* {widgetVisible &&
                <Window className={"widget-window"}
                    maximizeButton={() => null}
                    minimizeButton={() => null}
                    initialWidth={600}
                    resizable={false}>
                    <div className={"container content"}>
                        <div id="bookMe4Widget">
                            <iframe src="https://localhost:44365/12378617/Clients/BookMe4?widgetId=15f6af07-39c5-473e-b053-96653f77a406&embed=true"/>
                        </div>
                    </div>
                </Window>} */}
        </>

    );
}

export default MemberHeaderInfo;