import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import "./signOutButton.component.scss"
import { useDispatch } from "react-redux";
import { setAccessToken } from "../../store/auth/auth.reducer";
import store from "../../store/store";

type SignOutProps = {
    onClick?: () => void
}

const SignOutButton = (props: SignOutProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const onSignOutClick = () => {
        if (props.onClick) {
            props.onClick();
        }

        store.dispatch(setAccessToken(null));
        navigate("/");
    }

    return (
        <Button className="btn-sign-out" iconClass="btn-sign-out-icon" onClick={onSignOutClick}>
            Sign Out
        </Button>
    );
}

export default SignOutButton