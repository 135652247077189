import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectKioskSettings } from "../../store/kioskSettings/kioskSettings.selector";
import defaultOrganizationLogo from "../../assets/defaultOrganizationLogo.png";
import XplLoader from "../layout/xplLoader/xplLoader.component";
import KioskSettingsReload from "../kioskSettingsReload/kioskSettingsReload.component";
import { selectAuthInfo } from "../../store/auth/auth.selector";
import MemberAttend from "../../routes/member/memberAttend/member.attend.component";

const Home = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    var kioskSettings = useSelector(selectKioskSettings);
    const organizationLogo = kioskSettings.organizationLogo || defaultOrganizationLogo;
    const authInfo = useSelector(selectAuthInfo);

    useEffect(() => {
        if (authInfo.accessToken) {
            navigate("/memberpanel");
        }
        else {
            navigate("/");
        }

        setLoading(false);
    }, [authInfo.accessToken]);

    return (
        <>
            {loading && <XplLoader />}
            <KioskSettingsReload />

            <div className="kiosk-app-content">
                <div className="header">
                    <img className="company-logo" src={organizationLogo} />
                    <div className="company-header-text">Welcome to {kioskSettings.organizationName}</div>
                </div>
                {/* <Link className="entry-buttons" to="/login">
                    <div className="entry-button">
                        <div className="button-icon-section">
                            <div className="button-icon"></div>
                        </div>
                        <div className="button-text">
                            <div className="text">Member Attend</div>
                            <div className="description">Scan your access card or login to attend</div>
                        </div>
                        <div className="button-action-section">
                            <div className="button-action-icon"></div>
                        </div>
                    </div>
                </Link> */}
            </div>
            <MemberAttend />
        </>
    );
}

export default Home;