import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { ScannerSettings } from "../types/settings/scannerSettings.type";

export const createScannerHubConnection = () => {
    return new HubConnectionBuilder()
        .withUrl("https://localhost.perfectmind.com:37509/PerfectMindService/SignalR/kioskscannerhub")
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Debug)
        .build();
}

export const initializeScanner = (hubConnection: any, scannerSettings: ScannerSettings) => {
    return hubConnection.start()
        .then(() => {
            return hubConnection.invoke("InitializeScanner", {
                ComPort: scannerSettings.comPort,
                BaudRate: scannerSettings.baudRate,
                DataBits: scannerSettings.dataBits
            });
        });
}