import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../../components/layout/modal/modal.component";
import MemberHeaderInfo from "../../components/memberHeaderInfo/memberHeaderInfo.component";
import XplLoader from "../../components/layout/xplLoader/xplLoader.component";
import { getUserData } from "../../services/api/userApi.service";
import MemberClassList from "../memberClassList/memberClassList.component";
import IdleTimer from "../idleTimer/idleTimer.component";
import SignOutButton from "../signOutButton/signOutButton.component";
import { ClassItemType } from "../../types/class/classItem.type";
import { getSessions } from "../../services/api/attendUserApi";
import { setMemberClasses } from "../../store/memberClasses/memberClasses.action";
import { setUser } from "../../store/user/user.action";
import { selectUser } from "../../store/user/user.selector";
import "./memberPanel.component.scss";
import { IDLE_TIMER_TYPE } from "../../store/idleTimer/idleTimer.types";
import { selectMainTimerTimeout } from "../../store/kioskSettings/kioskSettings.selector";
import store from "../../store/store";
import { startMainTimer } from "../../store/idleTimer/idleTimer.reducer";

const MemberPanel = () => {
    const [loading, setLoading] = useState(true);
    const dispatch = useDispatch();
    const memberClasses = useSelector((state: any) => state.memberClasses);
    const user = useSelector(selectUser);
    const mainTimerTimeout = useSelector(selectMainTimerTimeout);

    useEffect(() => {
        const dataLoadingPromises = [];

        const userDataPromise = getUserData().then((userData) => {
            dispatch(setUser({
                id: userData.contactId,
                name: userData.contactDisplayName,
                photoUrl: userData.contactPhotoUrl,
                email: userData.contactEmail,
                phoneNumber: userData.phoneNumber
            }));
        });

        dataLoadingPromises.push(userDataPromise);

        const sessionsPromise = getSessions().then((sessions: ClassItemType) => {
            dispatch(setMemberClasses({
                yourClasses: sessions
            }));

            store.dispatch(startMainTimer(mainTimerTimeout));
        });

        dataLoadingPromises.push(sessionsPromise);

        Promise.all(dataLoadingPromises).then(() => {
            setLoading(false);
        })
    }, []);

    return (
        <>
            <Modal />
            <div className={"member-panel-container"}>
                <div className="header">
                    <MemberHeaderInfo user={user} />
                    <div className={"signout-button-container"}>
                        <SignOutButton />
                        <IdleTimer timerType={IDLE_TIMER_TYPE.MAIN} />
                    </div>
                </div>
                {loading && <XplLoader />}
                <MemberClassList yourClasses={memberClasses.yourClasses} />
            </div>
        </>
    );
}

export default MemberPanel