import * as React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import FormInput from "../formInput/formInput.component";
import XplLoader from "../layout/xplLoader/xplLoader.component"
import "./loginForm.component.scss";
import { memberSignIn } from "../../services/auth.service"
import { useSelector } from "react-redux";
import { selectKioskSettings } from "../../store/kioskSettings/kioskSettings.selector";
import { setAccessToken, setSignInError, setSignInLoading } from "../../store/auth/auth.reducer";
import store from "../../store/store";
import { selectAuthInfo } from "../../store/auth/auth.selector";

const LoginForm = () => {
    const navigate = useNavigate();
    const kioskSettings = useSelector(selectKioskSettings);
    const authInfo = useSelector(selectAuthInfo);

    const [formData, setFormData] = useState({
        userName: null,
        password: null,
        allowSubmit: false,
        validationError: ""
    });

    useEffect(() => {
        validateForm();
    }, [formData.userName, formData.password]);

    const onUsernameChange = (e: any) => {
        setFormData(state => ({ ...state, userName: e.target.value }));
    }

    const onPasswordChange = (e: any) => {
        setFormData(state => ({ ...state, password: e.target.value }));
    }

    const validateForm = () => {
        setFormData(state => ({ ...state, allowSubmit: !!state.userName && !!state.password }));
    }

    const onSubmitForm = (event: any) => {
        event.preventDefault();
        store.dispatch(setSignInLoading(true));
        setFormData(state => ({ ...state, validationError: "" }));

        memberSignIn({
            userName: formData.userName,
            password: formData.password,
            kioskId: kioskSettings.kioskId
        }).then((response: any) => {
            store.dispatch(setAccessToken(response.tokens.accessToken));
            store.dispatch(setSignInError(""));
            navigate("/memberpanel");
        }).catch((error) => {
            var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
            if (error.response && error.response.status == 403) {
                errorMessage = error.response.data.detail;
            }

            store.dispatch(setSignInError(errorMessage));
        }).finally(() => {
            store.dispatch(setSignInLoading(false));
            setFormData(state => ({ ...state, userName: "", password: "", validationError: "" }));
        });
    }

    return (
        <>
            {authInfo.loading && <XplLoader />}
            <form className={"login-form"} onSubmit={onSubmitForm}>
                <div className="header">Login to your account</div>
                {authInfo.signInError && (
                    <div className={"k-messagebox k-messagebox-error"}>
                        <div className="message-icon"></div>
                        <div className="message-text">{authInfo.signInError}</div>
                    </div>
                )}
                <FormInput label={"Email / Username"} type={"text"} className={"login-input"} required={true} onChange={onUsernameChange} value={formData.userName} />
                <FormInput label={"Password"} type={"password"} className={"password-input"} required={true} onChange={onPasswordChange} value={formData.password} />
                <div className="forgot-password-btn">Forgot Password?</div>

                <Button className="login-btn" type="submit" disabled={!formData.allowSubmit}>Login</Button>
            </form>
        </>
    );
}

export default LoginForm;