import axios from "axios";
import { getApiUrl } from "../kioskApp.service";
import { getLocalStorageAccessToken } from "../localStorage.service";
import { handleResponse } from "./apiHelper";

export const getUserData = () => {
    return axios.get(getApiUrl() + "/v1/kiosk/kioskuser/data", {
        headers: {
            "Authorization": "Bearer " + getLocalStorageAccessToken()
        }
    }).then((response: any) => {
        return handleResponse<any>(response.data);
    }).then((data) => {
        return data.accountMembers.find((item: any) => item.contactId == data.contactId);
    });
}

// TODO: Server is not ready
// export const getConfig = () => {
//     return axios.get("https://localhost:44330/kiosk/v1/config/get", {
//         headers: {
//             "Authorization": "Bearer " + getLocalStorageAccessToken()
//         }
//     }).then((response: any) => {
//         return response.data;
//     });
// }