import { createSlice } from "@reduxjs/toolkit";
import { getLocalStorageAccessToken, removeLocalStorageAccessToken, setLocalStorageAccessToken } from "../../services/localStorage.service";

const authSlice = createSlice({
    name: "auth",
    initialState: {
        accessToken: getLocalStorageAccessToken(),
        signInError: null,
        isLoggedIn: !!getLocalStorageAccessToken(),
        loading: false
    },
    reducers: {
        setAccessToken: (state, action) => {
            if (action.payload)
                setLocalStorageAccessToken(action.payload);
            else
                removeLocalStorageAccessToken();

            state.accessToken = action.payload;
            state.isLoggedIn = !!state.accessToken;
        },
        setSignInError: (state, action) => {
            state.signInError = action.payload;
        },
        setSignInLoading: (state, action) => {
            state.loading = action.payload;
        }
    }
});

export const { setAccessToken, setSignInError, setSignInLoading } = authSlice.actions;
export default authSlice.reducer;