import * as React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectKioskSettings } from "../../store/kioskSettings/kioskSettings.selector";
import { getKioskSettings } from "../../services/api/settingsApi.service";
import defaultOrganizationLogo from "../../assets/defaultOrganizationLogo.png";
import { selectAuthInfo } from "../../store/auth/auth.selector";
import { getKioskIdLocalStorage } from "../../services/localStorage.service";
import { useNavigate } from "react-router-dom";
import store from "../../store/store";
import { setKioskId, setKioskSettings } from "../../store/kioskSettings/kioskSettings.reducer";
import { setAccessToken } from "../../store/auth/auth.reducer";

const KioskSettingsReload = () => {
    const [intervalId, setIntervalId] = useState(null);
    const kioskSettings = useSelector(selectKioskSettings);
    const authInfo = useSelector(selectAuthInfo);
    const navigate = useNavigate();

    useEffect(() => {
        var intId = setInterval(() => {
            if (authInfo.isLoggedIn) {
                clearInterval(intId);
                return;
            }

            const kioskId = getKioskIdLocalStorage();
            if (!kioskId) {
                store.dispatch(setKioskId(null));
                store.dispatch(setAccessToken(null));
                clearInterval(intId);
                navigate("/");
                return;
            }

            getKioskSettings(kioskSettings.kioskId).then((settings) => {
                if (!settings.settings.profileActive) {
                    store.dispatch(setKioskId(null));
                    store.dispatch(setAccessToken(null));
                    clearInterval(intId);
                    navigate("/");
                    return;
                }

                if (kioskSettings.settings.modifiedDateString == settings.settings.modifiedDateString) {
                    return;
                }

                settings.backgroundImage = settings.settings.backgroundImage.content;
                settings.organizationLogo = settings.organizationLogo || defaultOrganizationLogo;

                store.dispatch(setKioskSettings(settings));
            }).catch((error) => {

            });
        }, 20000);

        setIntervalId(intId);

        return () => { clearInterval(intId); }
    }, [kioskSettings.settings.modifiedDateString, authInfo.isLoggedIn]);

    return (
        <></>
    );
}

export default KioskSettingsReload;