import * as React from "react";
import { useEffect } from "react";
import HomeRoute from "./routes/member/home/homeRoute.component";
import {
    createBrowserRouter,
    RouterProvider,
    useNavigate
} from "react-router-dom";
import MemberAttend from "./routes/member/memberAttend/member.attend.component";
import MemberPanel from "./components/memberPanel/memberPanel.component";
import ProtectedRoute from "./routes/member/auth/protectedRoute.component";
import 'bootstrap/dist/css/bootstrap.min.css';
import AdminHomeRoute from "./routes/admin/adminHomeRoute.component";
import { useSelector } from "react-redux";
import { selectKioskSettings } from "./store/kioskSettings/kioskSettings.selector";
import { getKioskIdLocalStorage } from "./services/localStorage.service";
import InitRoute from "./routes/initRoute.component";
import store from "./store/store";
import { setKioskId } from "./store/kioskSettings/kioskSettings.reducer";
import { selectAppInfo } from "./store/appInfo/appInfo.selector";
import { selectAuthInfo } from "./store/auth/auth.selector";

const KioskApp = () => {
    const kioskSettings = useSelector(selectKioskSettings);
    const appInfo = useSelector(selectAppInfo);

    useEffect(() => {
        const kioskId = getKioskIdLocalStorage();
        store.dispatch(setKioskId(kioskId));
    }, []);

    // useEffect(() => {
    //     store.dispatch(setAdminFlag(!kioskSettings.kioskId));
    // }, [kioskSettings.kioskId]);

    if (kioskSettings.kioskId === undefined) {
        var initRouter = createBrowserRouter([{
            path: "/",
            element: <InitRoute />
        }]);

        return (
            <RouterProvider router={initRouter} />
        );
    }

    // Member mode
    if (appInfo.initialized) {
        var router = createBrowserRouter([{
            path: "/",
            element: <HomeRoute />,
            children: [{
                path: "/login",
                element: <MemberAttend />
            }, {
                path: "/memberpanel",
                element: <ProtectedRoute>
                    <MemberPanel />
                </ProtectedRoute>,
            }]
        }, {
            path: "/memberpanel",
            element: <HomeRoute />
        }]);

        return (
            <RouterProvider router={router} />
        );
    }

    // Admin mode
    var router = createBrowserRouter([{
        path: "/",
        element: <AdminHomeRoute />
    }, {
        path: "/memberpanel",
        element: <AdminHomeRoute />
    }]);

    return (
        <RouterProvider router={router} />
    );
}

export default KioskApp;