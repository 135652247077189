import * as React from "react";
import { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectTimer } from "../../store/idleTimer/idleTimer.selector";
import { setAccessToken } from "../../store/auth/auth.reducer";
import store from "../../store/store";

type IdleTimerProps = {
    timerType: string
    // timeLeft: number
}

const IdleTimer = (props: IdleTimerProps) => {
    const navigate = useNavigate();
    const timer = useSelector((state: any) => selectTimer(state, props.timerType));
    const [timeLeft, setTimeLeft] = useState(0);
    const [timeoutId, setTimeoutId] = useState(null);

    const getMinutes = (timeLeft: number) => {
        return Math.floor(timeLeft / 60);
    }

    const getSeconds = (timeLeft: number) => {
        return Math.floor(timeLeft % 60);
    }

    const onIdle = () => {
        setTimeLeft(timer.timeout);
    }

    const onActive = (event: any) => {
        clearTimeout(timeoutId);
        setTimeLeft(0);
    }

    const idleTimer = useIdleTimer({
        onIdle, onActive,
        timeout: 10000
    });

    useEffect(() => {
        if (!timer.active || !timeLeft) return;

        var intId = setTimeout(() => {
            var newTimeLeft = timeLeft - 1;
            setTimeLeft(newTimeLeft);

            if (newTimeLeft <= 0) {
                store.dispatch(setAccessToken(null));
                navigate("/");
            }
        }, 1000);

        setTimeoutId(intId);

        return () => { clearTimeout(intId); }
    }, [timer.active, timeLeft]);

    return (
        <>
            {
                !!timeLeft &&
                <div className={"idle-timer"}>
                    <span>
                        {"You will get signed out in  "}
                        <span className={"it-minutes"}>{getMinutes(timeLeft)}’</span>
                        {" : "}
                        <span className={"it-seconds"}>{getSeconds(timeLeft)}”</span>
                    </span>
                </div>
            }
        </>
    );
}

export default IdleTimer;