import authReducer from "./auth/auth.reducer";
import idleTimerReducer from "./idleTimer/idleTimer.reducer";
import kioskSettingsReducer from "./kioskSettings/kioskSettings.reducer";
import { memberClassesReducer } from "./memberClasses/memberClasses.reducer";
import { configureStore } from "@reduxjs/toolkit";
import { userReducer } from "./user/user.reducer";
import appInfoReducer from "./appInfo/appInfo.reducer";


const store = configureStore({
    reducer: {
        auth: authReducer,
        idleTimer: idleTimerReducer,
        kioskSettings: kioskSettingsReducer,
        memberClasses: memberClassesReducer,
        user: userReducer,
        appInfo: appInfoReducer
    },
    // middleware: (getDefaultMiddleware) => [...getDefaultMiddleware()]
});

export default store;