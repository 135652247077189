import * as React from "react";
import { useSelector } from "react-redux";
import defaultBackgroundImage from "../assets/defaultBackgroundImage.png";
import XplLoader from "../components/layout/xplLoader/xplLoader.component";
import { selectKioskSettings } from "../store/kioskSettings/kioskSettings.selector";

const InitRoute = () => {
    var kioskSettings = useSelector(selectKioskSettings);
    const backgroundImage = kioskSettings.settings.backgroundImage && kioskSettings.settings.backgroundImage.content 
        ? kioskSettings.settings.backgroundImage.content : defaultBackgroundImage;

    return (
        <div className="kiosk-app-container" style={{ backgroundImage: `url(${backgroundImage})` }}>
            <XplLoader />
        </div>
    );
}

export default InitRoute