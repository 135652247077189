import { setKioskIdLocalStorage } from "../../services/localStorage.service";
import { createSlice } from "@reduxjs/toolkit";

const kioskSettingsSlice = createSlice({
    name: "kioskSettings",
    initialState: {
        settings: {},
        organizationName: null as string,
        organizationLogo: null as string,
        kioskId: undefined
    },
    reducers: {
        setKioskSettings: (state, action) => {
            state.settings = action.payload.settings;
            state.organizationName = action.payload.organizationName;
            state.organizationLogo = action.payload.organizationLogo;
        },
        setKioskId: (state, action) => {
            setKioskIdLocalStorage(action.payload);

            state.kioskId = action.payload;
        }
    }
});

export const { setKioskSettings, setKioskId } = kioskSettingsSlice.actions;
export default kioskSettingsSlice.reducer;