import * as React from "react";
import { Window } from "@progress/kendo-react-dialogs";
import "./attendanceReceipt2.component.scss"
import { ClassItemType } from "../../types/class/classItem.type";
import ClassDetail from "../classItem/classDetail/classDetail.component";
import { useSelector } from "react-redux";
import defaultOrganizationLogo from "../../assets/defaultOrganizationLogo.png";
import { selectUser } from "../../store/user/user.selector";
import { format } from "date-fns";

type AttendanceReceiptProps = {
    classItem: ClassItemType
}

const AttendanceReceipt2 = (props: AttendanceReceiptProps, ref?: any) => {
    const kioskSettings = useSelector((state: any) => state.kioskSettings);
    const user = useSelector(selectUser);
    const organizationLogo = kioskSettings.organizationLogo || defaultOrganizationLogo;
    const startTime = format(new Date(props.classItem.startTime), "EEEE, MMMM d, yyyy");

    return (
        <div ref={ref} className={"attendance-receipt"}>
            <div className={"header"}>
                <div className={"receipt-title"}>Attendance Receipt</div>
            </div>
            <div className={"content"}>
                <div className={"class-detail-title"}>Class Detail</div>
                <div className={"class-detail-container"}>
                    <div className={"class-date"}>
                        {startTime}
                    </div>
                    <ClassDetail classItem={props.classItem} />
                </div>

                <div className={"your-information-title"}>Your Information</div>
                <div className={"your-information-container container"}>
                    <div className={"row"}>
                        <div className={"col-6"}>Full Name</div>
                        <div className={"col-6"}>{user.name}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>Phone Number</div>
                        <div className={"col-6"}>{user.phoneNumber}</div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-6"}>Email</div>
                        <div className={"col-6"}>{user.email}</div>
                    </div>
                </div>
            </div>
            <div className={"footer"}>
                <div className={"organization-logo"} style={{ backgroundImage: `url(${organizationLogo})` }}></div>
            </div>
        </div>
    );
}

export default React.forwardRef(AttendanceReceipt2);