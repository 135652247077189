import * as React from "react";
import { useState, useEffect } from "react";
import { Window } from "@progress/kendo-react-dialogs";
import "./kioskSetup.component.scss"
import store from "../../../store/store";
import { useSelector } from "react-redux";
import { selectAppInfo } from "../../../store/appInfo/appInfo.selector";
import { createScannerHubConnection, initializeScanner } from "../../../services/scannerService";
import { scannerMemberSignIn } from "../../../services/auth.service";
import { selectKioskSettings } from "../../../store/kioskSettings/kioskSettings.selector";
import { getKioskSettings } from "../../../services/api/settingsApi.service";
import defaultOrganizationLogo from "../../../assets/defaultOrganizationLogo.png";
import { setKioskId, setKioskSettings } from "../../../store/kioskSettings/kioskSettings.reducer";
import { setHardwareSetupComplete } from "../../../store/appInfo/appInfo.reducer";
import { setAccessToken, setSignInError, setSignInLoading } from "../../../store/auth/auth.reducer";

const KioskSetupTitle = () => {
    return (
        <div className={"kiosk-setup-title container"}>
            <div className="row justify-content-center">
                <div className="col-auto">Kiosk Initialization</div>
            </div>
        </div>
    );
}

enum SetupStatusType {
    None = 0,
    Loading = 1,
    Success = 2,
    Fail = 3
}

const KioskSetup = () => {
    const [kioskSetupVisible, setkioskSetupVisible] = useState(true);
    const [errorMessage, setErrorMessage] = useState(null);
    const appInfo = useSelector(selectAppInfo);
    var kioskSettings = useSelector(selectKioskSettings);
    const organizationLogo = kioskSettings.organizationLogo || defaultOrganizationLogo;
    const [settingsSetup, setSettingsSetup] = useState({
        status: SetupStatusType.None,
        error: null
    });

    const [scannerSetup, setScannerSetup] = useState({
        status: SetupStatusType.None,
        error: null
    });

    useEffect(() => {
        setSettingsSetup({
            ...settingsSetup,
            status: SetupStatusType.Loading
        });

        getKioskSettings(kioskSettings.kioskId).then((settings) => {
            settings.backgroundImage = settings.settings.backgroundImage.content;
            settings.organizationLogo = settings.organizationLogo || defaultOrganizationLogo;

            store.dispatch(setKioskSettings(settings))

            // Reset kiosk id if there is no registered kiosk instance
            if (!settings.settings.kioskId) {
                store.dispatch(setAccessToken(null));

                // navigate("/");
                store.dispatch(setKioskId(null));

                return;
            }

            setSettingsSetup({
                ...settingsSetup,
                status: SetupStatusType.Success
            });

            setScannerSetup({
                ...settingsSetup,
                status: SetupStatusType.Loading
            });

            const scannerHubConnection = createScannerHubConnection();

            if (!settings.settings.scanner) {
                // setScannerSetup({
                //     status: SetupStatusType.Fail,
                //     error: "Scanner configuration is missing in kiosk instance setup."
                // });

                store.dispatch(setHardwareSetupComplete(true));
                return;
            }

            initializeScanner(scannerHubConnection, settings.settings.scanner)
                .then(() => {
                    setScannerSetup({
                        ...settingsSetup,
                        status: SetupStatusType.Success
                    });

                    store.dispatch(setHardwareSetupComplete(true));

                    scannerHubConnection.on("ScanComplete", (args: any) => {
                        store.dispatch(setSignInLoading(true));
                        store.dispatch(setSignInError(null));

                        scannerMemberSignIn({
                            scannerCode: args,
                            kioskId: kioskSettings.kioskId
                        }).then((response: any) => {
                            store.dispatch(setAccessToken(response.tokens.accessToken))
                        }).catch((error) => {
                            var errorMessage = "Unable to login to Kiosk. Please contact Xplor Recreation support for assistance";
                            if (error.response && error.response.status == 403) {
                                errorMessage = error.response.data.detail;
                            }

                            store.dispatch(setSignInError(errorMessage));
                        }).finally(() => {
                            store.dispatch(setSignInLoading(false));
                        });
                    });
                })
                .catch((error: any) => {
                    setScannerSetup({
                        status: SetupStatusType.Fail,
                        error: "Error occurred during scanner initialization"
                    });
                });
        }).catch((error) => {
            setSettingsSetup({
                status: SetupStatusType.Fail,
                error: error.message
            });
        })
    }, []);

    useEffect(() => {
        setErrorMessage(settingsSetup.error || scannerSetup.error);
    }, [settingsSetup.error, scannerSetup.error]);

    return (
        <>
            {kioskSetupVisible && <Window title={<KioskSetupTitle />} className={"kiosk-setup-window"}
                maximizeButton={() => null}
                minimizeButton={() => null}
                initialWidth={300}
                resizable={false}
                closeButton={() => null}
                draggable={false}
                modal>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-auto">
                            <div className="setup-item">
                                {settingsSetup.status == SetupStatusType.None && <span className="bi bi-gear"></span>}
                                {settingsSetup.status == SetupStatusType.Loading && <span className="bi bi-gear-fill setup-loading"></span>}
                                {settingsSetup.status == SetupStatusType.Success && <span className="bi bi-gear-fill setup-success"></span>}
                                {settingsSetup.status == SetupStatusType.Fail && <span className="bi bi-gear-fill setup-error"></span>}
                                <span className="setup-item-text">Loading kiosk settings</span>
                            </div>
                            {kioskSettings.settings.scanner && <div className="setup-item">
                                {scannerSetup.status == SetupStatusType.None && <span className="bi bi-gear"></span>}
                                {scannerSetup.status == SetupStatusType.Loading && <span className="bi bi-gear-fill setup-loading"></span>}
                                {scannerSetup.status == SetupStatusType.Success && <span className="bi bi-gear-fill setup-success"></span>}
                                {scannerSetup.status == SetupStatusType.Fail && <span className="bi bi-gear-fill setup-error"></span>}
                                <span className="setup-item-text">Scanner initialization</span>
                            </div>}
                        </div>
                    </div>
                    {errorMessage &&
                        <div className="row justify-content-center error-container">
                            <div className="col-1 error-icon"></div>
                            <div className="col-auto error-text">
                                {errorMessage}
                            </div>
                        </div>}
                </div>
            </Window>
            }
        </>
    );
}

export default KioskSetup