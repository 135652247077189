import React from "react";
import "./xplLoader.component.scss"
import Modal from "../modal/modal.component"
import { Loader } from "@progress/kendo-react-indicators";

const XplLoader = () => {
    return (
        <>
            <div className={"xpl-loader d-flex justify-content-center align-items-center"}>
                <Modal />
                <Loader type={"infinite-spinner"} size={"large"} />
            </div>
        </>
    );
}

export default XplLoader;