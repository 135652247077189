export const getKioskIdLocalStorage = () => {
    return localStorage.getItem("kioskId");
}

export const setKioskIdLocalStorage = (kioskId: string) => {
    if (kioskId) {
        localStorage.setItem("kioskId", kioskId);
    } else {
        localStorage.removeItem("kioskId");
    }
}

export const setLocalStorageAccessToken = (token: string) => {
    localStorage.setItem("accessToken", token);
}

export const getLocalStorageAccessToken = () => {
    return localStorage.getItem("accessToken");
}

export const removeLocalStorageAccessToken = () => {
    localStorage.removeItem("accessToken");
}