import { createSlice } from "@reduxjs/toolkit";

const appInfoSlice = createSlice({
    name: "appInfo",
    initialState: {
        initialized: false
    },
    reducers: {
        setHardwareSetupComplete: (state, action) => {
            state.initialized = action.payload;
        }
    }
});

export const { setHardwareSetupComplete } = appInfoSlice.actions;
export default appInfoSlice.reducer;