import { UserInfo } from "../../types/user/userInfo.type";
import { USER_ACTION_TYPES } from "./user.types";

const INITIAL_STATE = {
    user: {}
};

export const userReducer = (state = INITIAL_STATE, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case USER_ACTION_TYPES.SET_USER:
            return payload;
        default:
            return state;
    }
}