import { ClassItemType } from "../../types/class/classItem.type";
import { MEMBER_CLASSES_ACTION_TYPES } from "./memberClasses.types";

const INITIAL_STATE = {
    yourClasses: new Array<ClassItemType>()
};

export const memberClassesReducer = (state = INITIAL_STATE, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case MEMBER_CLASSES_ACTION_TYPES.SET_CLASSES:
            return {
                yourClasses: payload.yourClasses
            };
        default:
            return state;
    }
}